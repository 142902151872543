import { Box, Typography, keyframes, styled } from "decentraland-ui2"

const marquee = keyframes`
  from,0%, to {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
`

const TextMarqueeSection = styled("section")(() => {
  return {
    width: "100%",
    padding: 0,
    margin: 0,
    position: "relative",
    height: "83px",
    display: "flex",
    alignItems: "center",
    background:
      "linear-gradient(90deg, #FFBC5B 0%, #FC9965 17.07%, #FF7439 33.61%, #FF2D55 51%, #C640CD 66.8%, #A524B3 83.34%, #691FA9 100%)",
  }
})

const TextMarqueeContainer = styled(Box)({
  margin: "0 auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
})

const TextMarqueeTitle = styled(Typography)({
  display: "inline-block",
  paddingLeft: "100%",
  padding: "0",
  animation: `${marquee} 50s linear infinite`,
  fontSize: "37.6px",
  fontWeight: 400,
  lineHeight: "46.44px",
  textAlign: "left",
})

const TextMarqueeTitleSecond = styled(TextMarqueeTitle)({
  animationDelay: "50s",
})

export {
  TextMarqueeSection,
  TextMarqueeContainer,
  TextMarqueeTitle,
  TextMarqueeTitleSecond,
}
