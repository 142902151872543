import { Box, styled, useTheme } from "decentraland-ui2"

import Video, { VideProps } from "../../Video/Video"

const MissionContent = styled(Box)({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
})

const MissionVideo = styled(Video, {
  shouldForwardProp: (prop) => prop !== "isInView",
})((props: VideProps & { isInView: boolean }) => {
  const { isInView } = props
  const thema = useTheme()

  return {
    position: "sticky",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    opacity: isInView ? 1 : 0,
    objectFit: "cover",
    objectPosition: "right",
    transition: "opacity 1s",
    "@media (max-aspect-ratio: 16 / 9)": {
      "&.Video": {
        width: "100vw",
        height: "100vh",
      },
    },
    "@media (min-aspect-ratio: 16 / 9)": {
      "&.Video": {
        width: "100vw",
        height: "100vh",
      },
    },
    [thema.breakpoints.down("xs")]: {
      position: "fixed",
      "@media (max-aspect-ratio: 16 / 9)": {
        "&.Video": {
          width: "100vw",
          height: "53vh",
        },
      },
      "&.Video": {
        objectFit: "contain",
        objectPosition: "bottom",
      },
    },
  }
})

export { MissionContent, MissionVideo }
