import { MuiMarkdown } from "mui-markdown"

import { Box, Typography, styled, useTheme } from "decentraland-ui2"

import { MissionType } from "./LandingMissionsV2.types"

const MissionDetailContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isInView",
})((props: { isInView: boolean; missionId: MissionType }) => {
  const { isInView, missionId } = props
  const theme = useTheme()
  let radianColor =
    "radial-gradient(50% 61.46% at 0% 49.93%, rgba(211, 78, 222, 0.3) 0%, rgba(211, 78, 222, 0) 75%)"
  let mobileRadianColor =
    "radial-gradient(70.46% 45.24% at 51.99% 100.83%, rgba(211, 78, 222, 0.3) 0%, rgba(211, 78, 222, 0) 89.85%)"
  if (missionId === MissionType.CREATE) {
    radianColor =
      "radial-gradient(50% 61.46% at 0% 49.93%, rgba(0, 247, 0, 0.2) 0%, rgba(0, 247, 0, 0) 75%)"
    mobileRadianColor =
      "radial-gradient(71.73% 50.83% at 51.99% 100.83%, rgba(0, 247, 0, 0.3) 0%, rgba(0, 247, 0, 0) 75%);"
  } else if (missionId === MissionType.INFLUENCE) {
    radianColor =
      "radial-gradient(50% 61.46% at 0% 49.93%, rgba(255, 209, 70, 0.26) 0%, rgba(255, 209, 70, 0) 75%),linear-gradient(180deg, rgba(211, 78, 222, 0) 70%, rgba(211, 78, 222, 0.2) 100%)"
    mobileRadianColor =
      "radial-gradient(71.73% 50.83% at 51.99% 100.83%, rgba(255, 209, 70, 0.3) 0%, rgba(255, 209, 70, 0) 75%)"
  }
  return {
    paddingLeft: "144px",
    paddingRight: "50vw",
    position: "fixed",
    paddingTop: "30vh",
    top: 0,
    right: isInView ? "0" : "100%",
    bottom: 0,
    left: isInView ? 0 : "-100%",
    zIndex: 4,
    opacity: isInView ? 1 : 0,
    background: radianColor,
    transition: `${theme.transitions.create(
      ["top", "right", "bottom", "left", "opacity"],
      {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeInOut,
      }
    )}`,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      paddingTop: isInView ? "48vh" : "100vh",
      right: 0,
      bottom: isInView ? "56px" : "-48%",
      left: 0,
      zIndex: 2,
      opacity: isInView ? 1 : 0,
      justifyContent: "space-between",
      background: mobileRadianColor,
      width: "100vw",
      height: "100vh",
    },
  }
})

const MissionTextContainer = styled(Box)({})

const MissionActionsContainer = styled(Box)({
  marginTop: "34px",
})

const MissionNumber = styled(Typography)(() => {
  const theme = useTheme()
  return {
    fontSize: "36px",
    fontWeight: 500,
    lineHeight: "48px",
    letterSpacing: "-0.1em",
    textAlign: "left",
    marginRight: "24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
      marginRight: 0,
    },
  }
})

const MissionTitle = styled(Typography)(() => {
  const theme = useTheme()
  return {
    marginBottom: "32px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      flexDirection: "column",
      marginBottom: "24px",
    },
  }
})

const MissionDescription = styled(MuiMarkdown)({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
})

export {
  MissionDetailContainer,
  MissionTextContainer,
  MissionActionsContainer,
  MissionNumber,
  MissionTitle,
  MissionDescription,
}
