import { Box, Button, Typography, styled, useTheme } from "decentraland-ui2"

const FooterContainer = styled("footer")(() => {
  const theme = useTheme()
  return {
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: theme.palette.background.default,
    zIndex: 5,
    paddingTop: "50px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  }
})

const LinksContainer = styled(Box)(() => {
  const theme = useTheme()
  return {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "24px",
      paddingRight: "24px",
      marginTop: "66px",
      width: "100%",
    },
  }
})

const LinksWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  "&:nth-child(2)": { marginLeft: "70px" },
})

const LinksTitle = styled(Typography)(() => {
  const theme = useTheme()
  return {
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    marginBottom: "16px",
  }
})

const IconButton = styled(Button)(() => {
  const theme = useTheme()
  return {
    cursor: "pointer",
    justifyContent: "flex-start",
    marginBottom: "4px",
    "&.MuiButton-sizeMedium.MuiButton-textSecondary:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)":
      {
        color: theme.palette.text.primary,
        textTransform: "capitalize",
        "& .MuiButton-startIcon svg.MuiSvgIcon-root": {
          color: theme.palette.text.secondary,
        },
      },
    "&:hover": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      "&.MuiButton-sizeMedium.MuiButton-textSecondary:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)":
        {
          fontSize: "20px",
          fontWeight: 700,
          lineHeight: "32px",
          "& .MuiButton-startIcon svg.MuiSvgIcon-root": {
            fontSize: "24px",
          },
        },
    },
  }
})

const SubscribeContainer = styled(Box)(() => {
  const theme = useTheme()
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "470px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  }
})

const SubscribeSubtitle = styled(Typography)(() => {
  const theme = useTheme()
  return {
    color: theme.palette.text.disabled,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  }
})

const SubscribeTitle = styled(Typography)(() => {
  const theme = useTheme()
  return {
    color: "#fff",
    marginBottom: "16px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  }
})

const SocialContainer = styled(Box)(() => {
  const theme = useTheme()
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "24px",
    marginBottom: "16px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  }
})

const SocialIconWrapper = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
})

const SocialIconBox = styled(Box)({
  cursor: "pointer",
  fontSize: "28px",
})

export {
  FooterContainer,
  LinksContainer,
  LinksWrapper,
  LinksTitle,
  IconButton,
  SubscribeContainer,
  SubscribeSubtitle,
  SubscribeTitle,
  SocialContainer,
  SocialIconWrapper,
  SocialIconBox,
}
